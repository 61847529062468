import { mapActions } from 'vuex'
export default {
  async created() {
    // let option_name='company,occ,area,industry,hire_purchase,body_type,car_make,referral,ncd'
    await this.updateGlobalOptions()
  },
  methods: {
    ...mapActions('common', [ 'updateGlobalOptions']),
  }
}
