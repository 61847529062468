<template>
  <section
    v-if="!$route.meta.back&&$route.meta.title"
    class="header-container">
    <el-row>
      <el-col
        :xs="24"
        :sm="24"
        :md="16"
        :lg="16"
        :xl="17" >
        <div class="header-left  is-hidden-show-mobile">
          <!--          <h1 class="theme-blue">-->
          <!--            R&B-->
          <!--          </h1>-->
          <img
            src="../assets/images/Racing-Max.svg"
            alt="">
          <!--          <i-->
          <!--            :class="iconview"-->
          <!--            style="color: #409eff;cursor:pointer;"-->
          <!--            @click="chanView"></i>-->
          <font-awesome-icon
            icon="bars"
            @click="chanView"/>
        </div>
        <div
          v-if="$route.meta.title"
          class="header-title">
          <h1>
            {{$route.meta.title}}
          </h1>
        </div>
        <!--        <div-->
        <!--          v-if="$route.meta.back"-->
        <!--          class="header-title flex"-->
        <!--          @click="() => $router.back()"-->
        <!--        >-->
        <!--          <i class="el-icon-arrow-left"></i>-->
        <!--          <span>-->
        <!--            Back-->
        <!--          </span>-->
        <!--        </div>-->
      </el-col>
    </el-row>
  </section>
</template>

<script>
// import { logout } from '@/api'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'VHeader',
  data() {
    return {
      menuview: 'hidden-md-only',
      iconview: ['el-icon-s-unfold', 'hidden-md-and-up'],
      title:''
    }
  },
  computed:{
    ...mapState('headerClassChange', ['getClassShow']),
  },
  watch:{
    // $route:{
    //   immediate:true,
    //   handler(val){
    //     console.log('val',val)
    //   }
    // }
  },
  methods: {
    ...mapActions('headerClassChange', ['setClass']),
    chanView() {
      if (this.getClassShow != '') {
        this.iconview[0] = 'el-icon-s-fold'
        this.menuview = ''
        this.setClass('')
      } else {
        this.iconview[0] = 'el-icon-s-unfold'
        this.menuview = 'hidden-md-only'
        this.setClass('hidden-sm-and-down-slide')
      }
    },
    handlerLogout() {
      // debugger
      this.network().logout()
    },
    handlerHref() {
      // this.$router.push('/tickets/list/unresolved')
    },
    network() {
      return {
        logout: async () => {
          // try {
          //   await logout()
          // } catch(e) {
          //   console.log(e)
          // }
          //
          // localStorage.removeItem('token')
          // localStorage.removeItem('auth')
          // this.$router.replace('/login')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  //justify-content: space-between;
  //align-items: center;
  flex-direction: column;
  border-bottom: 0.5px solid #CBD5E0;
  height: 100%;
  margin-bottom: 30px;

  //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .header-left {
    background: white;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    font-size: 26px;
  }


  .logo-container {
    //display: flex;
    //align-items: center;
    //justify-content: space-between;

    img {
      margin-right: 15px;
      width: 26px;
    }

    span {
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
    }
  }

  .header-right {
    .user {
      color: #616266;
      font-size: 16px;
      font-weight: 500;
    }

    .el-button {
      margin-left: 10px;
    }
  }
  .header-title{
    color: #000000;
    font-weight: 700;
    width: 100%;
    padding: 10px 0;
    span{
      font-size: 16px;
    }
    &.flex{
      padding: 10px;
      align-items: center;
      i{
        margin-right: 10px;
      }
    }
    h1{
      color: #000;
      //padding: 0 20px ;
      font-size:36px ;
      //border-bottom: 0.5px solid #CBD5E0;
      //background: #f8f8f8;
    }
  }
}
</style>
